import { FC, useState } from 'react';

import CustomButton from 'shared/components/customButton/customButton';
import ConfirmationModal from './confirmation-modal';

export const Actions: FC<{ isChecked: boolean }> = ({ isChecked }) => {
	const [action, setAction] = useState('');

	return (
		<>
			<div className='width--full flex align-items--center justify-content--evenly'>
				<CustomButton
					buttonTitle='Accept'
					btnClassName='constitution-button accept'
					disabled={!isChecked}
					onButtonClick={() => setAction('accept')}
				/>
				<CustomButton
					buttonTitle='Reject'
					btnClassName='constitution-button reject'
					disabled={!isChecked}
					onButtonClick={() => setAction('reject')}
				/>
			</div>

			{action && <ConfirmationModal action={action} closeModal={() => setAction('')} />}
		</>
	);
};
