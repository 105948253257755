import { FC, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';

import { Actions } from '../component/actions';
import { Content } from '../component/content';
// import CommentModal from '../component/comment-modal';

type IUserInfo =
	| {
			id: string;
			reason: null | string;
			status: string;
	  }
	| undefined;

const constitution: FC = () => {
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [userInfo, setUserInfo] = useState<IUserInfo>(undefined);

	const fetchConstitution = async () => {
		setLoading(true);
		try {
			const response = await HttpService.get(API_CONFIG.path.constitution);
			console.log(response);
			setUserInfo(response);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchConstitution();
	}, []);

	return (
		<div className='constitution-wrapper'>
			{loading && <Loader />}
			{!loading && userInfo !== undefined && (
				<>
					<Content />

					{!isEmpty(userInfo) && userInfo.status ? (
						<p className={`${userInfo.status === 'Accept' ? 'text--green' : 'text--red'}`}>
							You have {userInfo.status}ed the constitution!!
						</p>
					) : (
						<>
							<div className='flex align-items--center mb--30'>
								<label className={`checkbox-wrapper width--full font--medium`}>
									<input type='checkbox' onChange={() => setChecked(!checked)} checked={checked} />
									<span className='check-mark' />{' '}
									<span className='ml--5'>I have read the Constitution</span>
								</label>
							</div>

							<Actions isChecked={checked} />
						</>
					)}
					{/* <CommentModal
						closeModal={() => {
							console.log('sdasdasd');
						}}
					/> */}
				</>
			)}
		</div>
	);
};

export default constitution;
