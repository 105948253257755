import isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import isNull from 'lodash/isNull';

import HttpService from 'shared/services/http.service';
import { EmailIcon, MobileIcon, UploadProfileIcon, WPIcon } from 'shared/components/icons/icons';
import { Loader } from 'shared/components/spinner/spinner';
import { API_CONFIG } from 'shared/constants/api';
import CustomButton from 'shared/components/customButton/customButton';
import { capitalizeWords, uploadFileToS3PreSigned } from 'shared/util/utility';
import { UserDetailsContext } from 'store/context/userDetailStore';
import AuthService from 'shared/services/auth.service';
import { IUserData, ROLES } from 'features/auth/interface/auth';

import { IPresignUrlResponse, IProfile, IProfileData, ITeacherPersonalDetails, tabArr } from '../interface/profile';
import { ALLOW_IMAGE_TYPES, MAX_FILE_SIZE } from '../constants/profile';

import ProfilePic from './profilePic';

const TABS = [tabArr.personal, tabArr.professional, tabArr.family];

const ProfileDetails: FC<{ userId?: string; className?: string }> = ({ userId, className }) => {
	const { pathname } = useLocation();
	const userData = AuthService.getAuthData() as IUserData;
	const isTeacher = useMemo(() => ROLES.TEACHER === userData.role, [userData]);

	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState<tabArr>(tabArr.personal);

	const { profileData: currentUserData, setProfileData: currentProfileData } = useContext(UserDetailsContext);
	const [profileData, setProfileData] = useState({} as IProfile);
	const [isUploadProfile, setIsUploadProfile] = useState(false);
	const [imageURL, setImageURL] = useState<string>('');
	const [imageUploadError, setImageUploadError] = useState<string>('');
	const [profileImgFile, setProfileImgFile] = useState<File>();

	const fetchProfile = async (id: string) => {
		setLoading(true);
		try {
			const profile: IProfileData = await HttpService.get(`${API_CONFIG.path.profile}/${id}`);

			if (profile) {
				const data = profileDataMapper(profile);
				setProfileData(data as IProfile);
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const profileDataMapper = useCallback((profile: IProfileData) => {
		const {
			first_name,
			last_name,
			profile_photo,
			profession,
			admission_year,
			life_time_member,
			roll_number,
			address,
			contact_number,
			email,
			birthdate,
			blood_group,
			house,
			education,
			passing_year,
			family,
			country_code,
			year_of_joining,
			role,
			year_of_leaving
		} = profile;

		const personalDetail = {
			Address: address
				? `${address.address ?? '-'} ${address.current_city || ''} ${address.current_country || ''}`
				: null,
			contact: `${country_code} ${contact_number}`,
			mail: email,
			'Date Of Birth': birthdate,
			'Blood Group': blood_group,
			degree: education[0]?.field || null,
			'Institute Name': education[0]?.institute_name || null
		};

		const teacherPersonalDetails: ITeacherPersonalDetails = {
			...personalDetail,
			'Year of Joining': year_of_joining as number | null
		};

		if (role !== ROLES.ALUMNI && !isNull(year_of_leaving)) {
			teacherPersonalDetails['Year of Leaving'] = year_of_leaving as number | null;
		}

		const alumniPersonalDetails = {
			...personalDetail,
			house: house && house.length > 0 ? house.join(', ') : null,
			'Admission Year': admission_year,
			'Pass-out Year': passing_year
		};

		return {
			name: `${first_name} ${last_name}`,
			profilePhoto: profile_photo,
			professionInfo:
				profession.designation || profession.organization
					? `${profession.designation}, ${profession.organization}`
					: '',
			city: `${profession.location || ''}`,
			batch: `Batch ${passing_year}`,
			isLifeMember: life_time_member,
			rollNumber: roll_number,
			[tabArr.personal]: [role !== ROLES.ALUMNI ? { ...teacherPersonalDetails } : { ...alumniPersonalDetails }],
			[tabArr.professional]: [
				{
					'Work Type': profession.work_type,
					'Name Of Organization': profession.organization,
					designation: profession.designation,
					location: profession.location
				}
			],
			[tabArr.family]:
				family.length > 0
					? family.map(({ birthdate, blood_group, name, occupation, relation }) => ({
							'Date Of Birth': birthdate,
							'Blood Group': blood_group,
							name,
							occupation,
							relation
					  }))
					: []
		};
	}, []);

	const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const image: any = e.target.files;
		setImageUploadError('');

		if (image[0]) {
			if (!ALLOW_IMAGE_TYPES.includes(image[0].type) || image[0].size > MAX_FILE_SIZE) {
				setImageUploadError('Maximum size 10 mb');
				return;
			} else {
				setImageURL(URL.createObjectURL(image[0]));
				setProfileImgFile(image[0]);
			}
		}
	};

	const handleImgUpload = async () => {
		setImageUploadError('');
		if (ALLOW_IMAGE_TYPES.includes((profileImgFile as File).type)) {
			try {
				const params = { key: (profileImgFile as File).name };
				const response: IPresignUrlResponse = await HttpService.post(API_CONFIG.path.profilePresignUrl, params);

				const profileKey: string = await uploadFileToS3PreSigned(response, profileImgFile as File);
				profileKey && uploadPresignedUrl(profileKey);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const uploadPresignedUrl = async (profileKey: string) => {
		const param = { profile_photo: profileKey };
		try {
			const profileUrl = await HttpService.post(API_CONFIG.path.AddUserProfilePhoto, param);
			currentProfileData((data) => ({ ...data, profile_photo: profileUrl.url, profile_key: profileKey }));
			setIsUploadProfile(false);
			setImageUploadError('');
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (userId) {
			fetchProfile(userId);
		} else if (!isEmpty(currentUserData)) {
			const data = profileDataMapper(currentUserData);
			setProfileData(data as IProfile);
			setLoading(false);
		}
	}, [userId, currentUserData]);

	return (
		<div className='profile-details-wrapper'>
			{loading && (
				<div className='pt--40'>
					<Loader />
				</div>
			)}
			{!loading && !isEmpty(profileData) && (
				<>
					<div className='flex align-items--center pl--20 pr--20 pt--20 pb--10'>
						<ProfilePic
							isEdit={pathname === '/profile'}
							url={profileData.profilePhoto}
							setIsUploadProfile={() => setIsUploadProfile(true)}
						/>

						<div className='alumni-profile-details-wrapper'>
							<p className='alumni-name'>{capitalizeWords(profileData.name)}</p>

							<p className='occupation mt--4'>{profileData.professionInfo}</p>
							{!isTeacher && (
								<>
									<p className='batch'>{profileData.batch}</p>
									<div className='flex align-items--center mt--4'>
										<p className='roll-no'>{profileData.rollNumber}</p>
										{profileData.isLifeMember && <p className='life-member'>Life Member</p>}
									</div>
								</>
							)}
						</div>
					</div>
					{pathname !== '/profile' && (
						<div className='alumni-contact-wrapper'>
							<a
								className='contact-wrapper'
								href={`tel:${profileData?.personal[0] ? profileData?.personal[0].contact : ''}`}
								onClick={(e) => e.stopPropagation()}
							>
								<MobileIcon className='mobile-icon' />
								<p className='contact'>Phone Call</p>
							</a>
							<ReactWhatsapp
								number={`${profileData?.personal[0] ? profileData?.personal[0].contact : ''}`}
								element='webview'
								className='contact-wrapper ml--15 mr--15 cursor--pointer'
								onClick={(e) => e.stopPropagation()}
							>
								<WPIcon className='wp-icon' />
								<p className='contact'>Message</p>
							</ReactWhatsapp>
							<a
								className={`contact-wrapper ${
									profileData?.personal[0] && profileData?.personal[0].mail
										? ''
										: 'disabled pointer-events--none'
								}`}
								href={`mailto:${profileData?.personal[0] ? profileData?.personal[0].mail : ''}`}
								onClick={(e) => e.stopPropagation()}
							>
								<EmailIcon className='email-icon' />
								<p className='contact'>E-mail</p>
							</a>
						</div>
					)}
					<div className={`tab-wrapper tab-wrapper--${activeTab}`}>
						{TABS.map((tab, index) => (
							<p
								className={`tab ${activeTab === tab ? 'tab--active' : ''}`}
								key={index}
								onClick={() => setActiveTab(tab)}
							>
								{tab}
							</p>
						))}
					</div>
					<div className={`details-wrapper details-wrapper--${activeTab} ${className || ''}`}>
						{profileData[activeTab].map((obj) =>
							Object.entries(obj).map(([label, value], index) => (
								<div key={index} className='flex align-items--center justify-content--between details'>
									<p className='detail-label'>{label}</p>
									<p
										className={`detail-value ${label === 'mail' ? '' : 'text--capitalize'}`}
										title={value as string | undefined}
									>
										{value || '-'}
									</p>
								</div>
							))
						)}
						{profileData[activeTab].length === 0 && (
							<p className='text--center opacity--50 pt--40 pb--40 text--capitalize'>
								No {activeTab} details found.
							</p>
						)}
					</div>
					{isUploadProfile && (
						<div className='modal-wrapper z-index--7'>
							<div className='modal-content pt--20 pb--20'>
								<p className='upload-title'>Upload Photo</p>
								<label htmlFor='profile-pic'>
									<input
										type='file'
										name='profile-pic'
										id='profile-pic'
										accept='image/*'
										hidden
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageSelect(e)}
									/>

									<div
										className={`upload-img-wrapper ${
											imageURL === '' ? 'flex justify-content--center' : ''
										}`}
									>
										{imageURL === '' ? (
											<div>
												<UploadProfileIcon className='upload-profile-icon' />
												<p className='upload-photo-text'>Upload your picture here</p>
												<p className='photo-size-text'>Supported format: JPG, JPEG or PNG.</p>
												{imageUploadError && (
													<p className='error text--center'>{imageUploadError}</p>
												)}
											</div>
										) : (
											<div className='justify-align--center flex--column'>
												<div className='profile-pic-wrapper'>
													<img className='profile-pic' src={imageURL} alt='profile-pic' />
												</div>
												<p className='pt--20 font-size--sm text--gray'>
													Change image, or{' '}
													<span className='text--black font-bold'>browse</span>
												</p>
											</div>
										)}
									</div>
								</label>
								<div className='browse-button-wrapper mt--20'>
									<CustomButton
										buttonTitle='Cancel'
										btnClassName='secondary-button mr--10'
										onButtonClick={() => {
											setIsUploadProfile(false);
											setImageUploadError('');
											setProfileImgFile(undefined);
											setImageURL('');
										}}
									/>
									<CustomButton
										buttonTitle='Update Photo'
										btnClassName='primary-button ml--10'
										disabled={imageURL === ''}
										onButtonClick={() => !isEmpty(imageURL) && handleImgUpload()}
									/>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ProfileDetails;
