import { FC } from 'react';
// import { useNavigate } from 'react-router-dom';
import pdf from 'assets/doc/OBSSA-Constitution.pdf';

import { ConstitutionIcon } from 'shared/components/icons/icons';

const Constitution: FC = () => {
	// const navigate = useNavigate();
	const openPDF = () => {
		window.open(pdf, '_blank', '');
	};
	return (
		<div className='updates-wrapper constitution-tab-wrapper' onClick={openPDF}>
			<div className='swiper-wrapper'>
				<div className='birthday-alumni-wrapper width--full'>
					<div className='flex align-items--start'>
						<div className='update-img-wrapper'>
							<ConstitutionIcon />
						</div>
						<div className='birthday-alumni-details pt--5 pb--5'>
							<p
								className='news-type'
								style={{
									background: '#401a8e33'
								}}
							>
								New Constitution Available
							</p>
							<p className='alumni-number'>Tap here to read in detail</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Constitution;
