import { FC } from 'react';
import pdf from 'assets/doc/test.pdf';
import { ConstitutionAcceptIcon } from 'shared/components/icons/icons';

export const Content: FC = () => {
	const openPDF = () => {
		window.open(pdf, '_blank', '');
	};
	return (
		<>
			{/* <p className='text--secondary' dangerouslySetInnerHTML={{ __html: content }} /> */}
			<div className='constitution-check-box flex align-items--center' onClick={openPDF}>
				<ConstitutionAcceptIcon />
				<span className='link'>Click to open Constitution</span>
			</div>
		</>
	);
};
