import { FC, useMemo, useState } from 'react';
import { CloseIcon, ConstitutionAcceptIcon, ConstitutionRejectIcon } from 'shared/components/icons/icons';
import CustomButton from 'shared/components/customButton/customButton';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

interface IProps {
	action: string;
	closeModal: () => void;
}

const ConfirmationModal: FC<IProps> = ({ action, closeModal }) => {
	const [rejectionReason, setRejectionReason] = useState('');
	const [loading, setLoading] = useState(false);

	const isAccept = useMemo(() => action === 'accept', [action]);

	const onSubmit = async () => {
		setLoading(true);

		let params: { status: string; reason?: string } = { status: 'Accept' };
		if (!isAccept) {
			params = {
				status: 'Reject',
				reason: rejectionReason
			};
		}

		try {
			await HttpService.post(API_CONFIG.path.constitution, params);
			setLoading(false);
			closeModal();
		} catch (err) {
			setLoading(false);
			console.error(err);
		}
	};

	return (
		<div className='modal-wrapper constitution-modal-wrapper'>
			<div className='modal-content constitution-modal-content text--center'>
				<CloseIcon className='close-button position--absolute' onClick={closeModal} />
				{isAccept ? <ConstitutionAcceptIcon /> : <ConstitutionRejectIcon />}
				{/* <p className='font-size--lg font--bold'>New Constitution {isAccept ? 'Accept' : 'Reject'}</p> */}

				<p className='font-size--md mt--20'>
					I {!isAccept ? 'do not agree to' : 'accept'} the newly drafted constitution of OBSSA.
				</p>

				{!isAccept && (
					<textarea
						className='rejection-reason-text-area'
						value={rejectionReason}
						placeholder='Could you please share your reasoning?'
						onChange={({ target }) => setRejectionReason(target.value)}
					/>
				)}

				<CustomButton
					buttonTitle={isAccept ? 'Accept' : 'Reject'}
					btnClassName={`constitution-button accept mt--20 ${isAccept ? 'accept' : 'reject'}`}
					disabled={loading}
					onButtonClick={onSubmit}
				/>
			</div>
		</div>
	);
};

export default ConfirmationModal;
