import { FC, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from 'hoc/layout/layout';

import Spinner from 'shared/components/spinner/spinner';
import { IState } from 'shared/interface/state';
import Login from 'features/auth/container/Login';
import Dashboard from 'features/dashboard/container/Dashboard';
import Splash from 'features/splash/container/splash';
import NearByMap from 'features/nearByMap/container/nearByMap';
import Search from 'features/search/container/search';
import ProfileDetails from 'features/profile/components/profileDetails';
import SuggestionCorner from 'features/suggestionCorner/container/suggestionCorner';
import Setting from 'features/setting/container/setting';
import InstallationPrompt from './shared/components/InstallationPrompt/InstallationPrompt';
import EditProfile from 'features/profile/components/editProfile';
import AllMemories from 'features/allMemories/container';
import Constitution from 'features/constitution/container/constitution';
// signUp
import SignUpProfile from 'features/signUp/profile/container/Profile';
import AlumniDetails from 'features/signUp/alumniDetails/container/AlumniDetails';
import SuccessfulCompletion from 'features/signUp/profile/component/SuccessfulCompletion';
import SignUpLayout from 'hoc/layout/signUpLayout';

const App: FC<{ fcmToken: string }> = ({ fcmToken }) => {
	const isLogin: boolean = useSelector((state: IState) => state.auth.isLogin);
	const isSignUpRoute: boolean = useSelector((state: IState) => state.auth.isSignUpRoute);

	const getLoggedInRouts = () => {
		if (isSignUpRoute) {
			return (
				<SignUpLayout>
					<Routes>
						<Route path='/profile' element={<SignUpProfile />} />
						<Route path='/profile/details' element={<AlumniDetails />} />

						<Route path='/successful-completion' element={<SuccessfulCompletion />} />
						<Route path='*' element={<Navigate to='/profile' />} />
					</Routes>
				</SignUpLayout>
			);
		} else {
			return (
				<Layout>
					<Suspense fallback={<Spinner />}>
						<Routes>
							<Route path='/home' element={<Dashboard fcmToken={fcmToken} />} />
							<Route path='/maps' element={<NearByMap />} />
							<Route path='/search' element={<Search />} />
							<Route path='/profile' element={<ProfileDetails />} />
							<Route path='/suggestion-corner' element={<SuggestionCorner />} />
							<Route path='/settings' element={<Setting />} />
							<Route path='/edit-profile' element={<EditProfile />} />
							<Route path='/all-memories' element={<AllMemories />} />
							<Route path='/constitution' element={<Constitution />} />
							<Route path='*' element={<Navigate replace to='/home' />} />
						</Routes>
					</Suspense>
				</Layout>
			);
		}
	};

	return (
		<>
			<InstallationPrompt />
			{isLogin ? (
				getLoggedInRouts()
			) : (
				<Routes>
					<Route path='/' element={<Splash />} />
					<Route path='/login' element={<Login />} />
					<Route path='*' element={<Navigate replace to='/login' />} />
				</Routes>
			)}
		</>
	);
};

export default App;
